import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../../components/SearchHeader/SearchBar";

import "./AddTextModal.scss";


const AddTextModal = (props) => {
  const { classId, texts, showModal, onHide, onFetchTexts, onAddTexts } = props;
  const [selectedTexts, setSelectedTexts] = useState([]);

  useEffect(() => {
    if (showModal) {
      setSelectedTexts([]);
    }
  }, [showModal]);

  const handleSelectText = (ev, data) => {
    const selected = ev.target.checked;
    const selectedText = {
      class_id: classId,
      text_sha1: data.sha1,
      text_title: data.title
    };
    if(selected) {
      setSelectedTexts(selectedTexts.concat([selectedText]));
    } else {
      setSelectedTexts(selectedTexts.filter(text => text.text_sha1 !== data.sha1));
    }
  };

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        onClick={(ev) => handleSelectText(ev, row)}
      />
    );
  };

  const handleClose = () => {
    setSelectedTexts([]);
    if (typeof onHide === "function") {
      onHide();
    }
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 250 },
    { field: 'author', headerName: 'Author', width: 100 },
    { field: 'plan', headerName: 'Plan', width: 100 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'id', headerName: '-', width: 100, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    if (showModal){
      onFetchTexts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal,onFetchTexts]);

  const handleSearch = (searchTerm) => {
    onFetchTexts(searchTerm);
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={handleClose} className="new-class-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add New Text to This Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="book-search">
            <SearchBar placeholder="Search: Text with Title" onSearch={handleSearch} />
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={texts}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={() => onAddTexts(selectedTexts)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AddTextModal.propTypes = {
  classId: PropTypes.string,
  showModal: PropTypes.bool,
  texts: PropTypes.array,
  textSha: PropTypes.array,
  onHide: PropTypes.func,
  onFetchTexts: PropTypes.func,
  onAddTexts: PropTypes.func
};

export default AddTextModal;
