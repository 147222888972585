import { makeApiCall } from "../../../helpers/api-client";
import { setNextRoute, throwApiError } from "../../../common/actions/actions";
import { signInSuccess } from "../../Login/state/actions";
import { formatUser } from "../../Login/state/formatters";


export const signUp = (payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall("rpc/signup", {
      method: 'post',
      data: payload,
      headers: {
        'Accept': 'application/vnd.pgrst.object+json'
      }
    });
    const formattedResponse = formatUser(response.data);
    localStorage.setItem("authToken", formattedResponse.token);
    dispatch(signInSuccess(formattedResponse));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(setNextRoute("/createprofile"));
  } catch (error) {
    if (error?.response?.data?.code === "23505") {
      dispatch(throwApiError({
        type: "alert",
        message: "User already exists. Please sign in."
      }));
    } else {
      dispatch(throwApiError());
    }

    if (typeof onFail === "function") {
      onFail();
    }
  }
};
