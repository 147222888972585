import { makeApiCall } from "../../../helpers/api-client";
import { setNextRoute, throwApiError } from "../../../common/actions/actions";
import * as Actions from "./action-types";
import { formatUser } from "./formatters";

export const signInSuccess = (payload) => ({
  type: Actions.SIGN_IN_SUCCESS,
  payload
});


export const signIn = (payload, onComplete) => async dispatch => {
  try {
    const response = await makeApiCall("rpc/login", {
      method: 'post',
      data: payload,
      headers: {
        'Accept': 'application/vnd.pgrst.object+json'
      }
    });
    const formattedResponse = formatUser(response.data);
    const { id, role, token } = formattedResponse;
    localStorage.setItem("authToken", token);
    dispatch(signInSuccess(formattedResponse));
    dispatch(getProfile(id, role));

    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      dispatch(throwApiError({
        type: "alert",
        message: "Email or Password is incorrect. Please try again."
      }));
    } else {
      dispatch(throwApiError({
        type: "alert",
        message: "There was a problem with your request. Please try again."
      }));
    }
    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

const getProfileSuccess = payload => ({
  type: Actions.GET_PROFILE_SUCCESS,
  payload
});

const getProfileFailure = payload => ({
  type: Actions.GET_PROFILE_FAIL,
  payload
});

export const getProfile = (id, role) => async dispatch => {
  try {
    const response = await makeApiCall("/fc_profile");
    if(response.data.length === 0) {
      dispatch(setNextRoute("/createprofile"));
    } else {
      dispatch(getProfileSuccess(response.data[0]));
      if (id && (role === "student" || role ==="teacher") ) {
        dispatch(getSubscription(id));
      } else {
        dispatch(setNextRoute("/organization"));
      }
    }
  } catch (error) {
    dispatch(getProfileFailure(error));
  }
};

const getSubscriptionFailure = payload => ({
  type: Actions.GET_SUBSCRIPTION_FAIL,
  payload
});

export const getSubscription = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_subscriptions?owner=eq.${id}&order=id.desc`);
    if (response.data.length !== 0 && (response.data[0].auto_renew_status || response.data[0].is_expired === 0)) {
      dispatch(setNextRoute("/user/home"));
    } else {
      dispatch(setNextRoute("/plans"));
    }
  } catch (error) {
    dispatch(getSubscriptionFailure(error));
  }
};

export const setResetEmail = payload => ({
  type: Actions.SET_RESET_EMAIL,
  payload
});

